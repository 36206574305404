import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Button from '../../button/button'
import * as s from './index.module.sass'

interface ImageTabsProps {
  images: { image: IGatsbyImageData; tabName: string }[]
  imageAlt: string
  imageTitle: string
}

const ImageTabs: React.FC<ImageTabsProps> = ({
  images,
  imageAlt,
  imageTitle,
}) => {
  const [selected, setSelected] = React.useState<number>(0)

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center">
        <GatsbyImage
          image={images[selected].image}
          objectFit="contain"
          alt={imageAlt}
          title={imageTitle}
          className={s.image}
        />
      </div>
      <div className="flex">
        {images.map((item, index) => (
          <Button
            key={index}
            onClick={() => setSelected(index)}
            className={s.button}
            isBoxShadow={false}
            color={selected === index ? 'light-blue' : 'dark-blue'}
          >
            {item.tabName}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default ImageTabs
