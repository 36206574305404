import React from 'react'

const RevitIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="214.06"
      height="195.24"
      viewBox="0 0 214.06 195.24"
      {...props}
    >
      <title>Autodesk Revit logo - Brandlogos.net</title>
      <path
        d="M279.65,305.62c10.72-6.35,21.31-12.94,32.07-19.2.1,53.21,0,106.42,0,159.64-5.26,3.2-10.56,6.33-15.81,9.55-6.34,3.76-12.6,7.68-19,11.36V307.69A9.68,9.68,0,0,1,279.65,305.62Z"
        transform="translate(-276.97 -286.38)"
        fill="#679ae7"
      />
      <path
        d="M311.72,286.42q75.89-.07,151.79,0c6-.3,11.75,5,11.72,11,0,8.27,0,16.55,0,24.82q0,61.89,0,123.79-81.72,0-163.45,0C311.72,392.84,311.82,339.63,311.72,286.42Zm49.86,124.22c7.44.07,14.89,0,22.34,0q0-16,0-31.9c3.13,0,6.26,0,9.39-.05,3.34,6.37,6.4,12.9,9.64,19.33,2.44,5.58,6,11.41,12.15,13.42a24.06,24.06,0,0,0,16.54-.77c0-4.44,0-8.88,0-13.31-1.83-.23-3.81-.2-5.41-1.25a8.82,8.82,0,0,1-3-3.59q-4.89-9.27-9.82-18.51c5-2.68,9.81-6.44,12.2-11.77,2.41-5,1.71-10.77,1.77-16.18a21.37,21.37,0,0,0-6.84-15.88c-6.28-5.69-15-7.81-23.25-8.09-11.88,0-23.77,0-35.65,0C361.55,351.6,361.62,381.13,361.58,410.64Z"
        transform="translate(-276.97 -286.38)"
        fill="#186bff"
      />
      <path
        d="M383.92,337.44c4.45.17,9-.39,13.36.36,3.66.73,7,3.53,7.56,7.33a78.09,78.09,0,0,1,.18,9.47,9.4,9.4,0,0,1-6.35,8.67c-4.75,1.55-9.83.67-14.74.91Q383.91,350.82,383.92,337.44Z"
        transform="translate(-276.97 -286.38)"
        fill="#186bff"
      />
      <path
        d="M361.58,410.64c0-29.52,0-59,0-88.55,11.88,0,23.77,0,35.65,0,8.28.29,17,2.41,23.25,8.09a21.37,21.37,0,0,1,6.84,15.88c-.05,5.41.64,11.14-1.77,16.18-2.39,5.33-7.16,9.09-12.2,11.77q4.92,9.24,9.82,18.51a8.82,8.82,0,0,0,3,3.59c1.6,1,3.59,1,5.41,1.25,0,4.44,0,8.88,0,13.31a24.06,24.06,0,0,1-16.54.77c-6.1-2-9.7-7.85-12.15-13.42-3.24-6.43-6.3-13-9.64-19.33-3.13.1-6.26,0-9.39.05q0,16,0,31.9C376.47,410.65,369,410.71,361.58,410.64Zm22.34-73.2q0,13.37,0,26.74c4.9-.23,10,.64,14.74-.91a9.4,9.4,0,0,0,6.35-8.67,78.09,78.09,0,0,0-.18-9.47c-.59-3.8-3.9-6.6-7.56-7.33C392.88,337,388.37,337.62,383.92,337.44Z"
        transform="translate(-276.97 -286.38)"
        fill="#fff"
      />
      <path
        d="M475.23,322.26H491q0,79.68,0,159.36H306.66a12.27,12.27,0,0,1-9-5.48,11.09,11.09,0,0,1-1.73-6.66c0-4.62,0-9.25,0-13.87,5.25-3.21,10.55-6.35,15.81-9.55q81.73,0,163.45,0Q475.27,384.16,475.23,322.26Z"
        transform="translate(-276.97 -286.38)"
        fill="#0c3d91"
      />
    </svg>

    // <svg
    //   id="Layer_1"
    //   data-name="Layer 1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="214.06"
    //   height="195.24"
    //   viewBox="0 0 214.06 195.24"
    //   {...props}
    // >
    //   <title>Autodesk Revit logo - Brandlogos.net</title>
    //   <path
    //     d="M279.65,305.62c10.72-6.35,21.31-12.94,32.07-19.2.1,53.21,0,106.42,0,159.64-5.26,3.2-10.56,6.33-15.81,9.55-6.34,3.76-12.6,7.68-19,11.36V307.69A9.68,9.68,0,0,1,279.65,305.62Z"
    //     transform="translate(-276.97 -286.38)"
    //     fill="#679ae7"
    //   />
    //   <path
    //     d="M311.72,286.42q75.89-.07,151.79,0c6-.3,11.75,5,11.72,11,0,8.27,0,16.55,0,24.82q0,61.89,0,123.79-81.72,0-163.45,0C311.72,392.84,311.82,339.63,311.72,286.42Zm49.86,124.22c7.44.07,14.89,0,22.34,0q0-16,0-31.9c3.13,0,6.26,0,9.39-.05,3.34,6.37,6.4,12.9,9.64,19.33,2.44,5.58,6,11.41,12.15,13.42a24.06,24.06,0,0,0,16.54-.77c0-4.44,0-8.88,0-13.31-1.83-.23-3.81-.2-5.41-1.25a8.82,8.82,0,0,1-3-3.59q-4.89-9.27-9.82-18.51c5-2.68,9.81-6.44,12.2-11.77,2.41-5,1.71-10.77,1.77-16.18a21.37,21.37,0,0,0-6.84-15.88c-6.28-5.69-15-7.81-23.25-8.09-11.88,0-23.77,0-35.65,0C361.55,351.6,361.62,381.13,361.58,410.64Z"
    //     transform="translate(-276.97 -286.38)"
    //     fill="#186bff"
    //   />
    //   <path
    //     d="M383.92,337.44c4.45.17,9-.39,13.36.36,3.66.73,7,3.53,7.56,7.33a78.09,78.09,0,0,1,.18,9.47,9.4,9.4,0,0,1-6.35,8.67c-4.75,1.55-9.83.67-14.74.91Q383.91,350.82,383.92,337.44Z"
    //     transform="translate(-276.97 -286.38)"
    //     fill="#186bff"
    //   />
    //   <path
    //     d="M361.58,410.64c0-29.52,0-59,0-88.55,11.88,0,23.77,0,35.65,0,8.28.29,17,2.41,23.25,8.09a21.37,21.37,0,0,1,6.84,15.88c-.05,5.41.64,11.14-1.77,16.18-2.39,5.33-7.16,9.09-12.2,11.77q4.92,9.24,9.82,18.51a8.82,8.82,0,0,0,3,3.59c1.6,1,3.59,1,5.41,1.25,0,4.44,0,8.88,0,13.31a24.06,24.06,0,0,1-16.54.77c-6.1-2-9.7-7.85-12.15-13.42-3.24-6.43-6.3-13-9.64-19.33-3.13.1-6.26,0-9.39.05q0,16,0,31.9C376.47,410.65,369,410.71,361.58,410.64Zm22.34-73.2q0,13.37,0,26.74c4.9-.23,10,.64,14.74-.91a9.4,9.4,0,0,0,6.35-8.67,78.09,78.09,0,0,0-.18-9.47c-.59-3.8-3.9-6.6-7.56-7.33C392.88,337,388.37,337.62,383.92,337.44Z"
    //     transform="translate(-276.97 -286.38)"
    //     fill="#fff"
    //   />
    //   <path
    //     d="M475.23,322.26H491q0,79.68,0,159.36H306.66a12.27,12.27,0,0,1-9-5.48,11.09,11.09,0,0,1-1.73-6.66c0-4.62,0-9.25,0-13.87,5.25-3.21,10.55-6.35,15.81-9.55q81.73,0,163.45,0Q475.27,384.16,475.23,322.26Z"
    //     transform="translate(-276.97 -286.38)"
    //     fill="#0c3d91"
    //   />
    // </svg>
  )
}

export default RevitIcon
